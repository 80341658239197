import { message } from "antd";
import ls from "utils/ls";

const actions = (dispatch) => ({
  login: (token, studentInfo) => {
    ls.set("studentInfo", studentInfo);
    dispatch({ type: "LOGIN", token: token, studentInfo: studentInfo });
  },
  logout: async () => {
    ls.remove("studentInfo");
    dispatch({ type: "LOGOUT" });
  },
  onSuccess: (info) => {
    message.open({ type: "success", content: info });
  },
  onError: (info) => {
    message.open({ type: "error", content: info });
  },
});

export default actions;
