import React, { useEffect, useState } from "react";
import { Tag, Avatar, DatePicker, Select } from "antd";
import { instance } from "utils/axios";
import { FieldTimeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "dayjs/locale/mn"; // Import Mongolian locale
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import moment from "moment";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale("mn");

const ScheduleAttendance = () => {
  const [scheduleAtt, setScheduleAtt] = useState([]);
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [daysInMonth, setDaysInMonth] = useState(dayjs().daysInMonth());
  const [month, setMonth] = useState(dayjs());

  useEffect(() => {
    fetchCourses();
  }, [date]);

  const fetchCourses = () => {
    instance({
      method: "get",
      url: `/student/schedule/attendance?date=${date}`,
    })
      .then((response) => {
        setScheduleAtt(response.data);
      })
      .catch((error) => console.error(`Failed to fetch `, error));
  };

  const daysArray = [...Array(daysInMonth)].map((_, index) => index + 1);
  const handleDateClick = (day) => {
    const formattedDate = dayjs(`${month.format("YYYY-MM")}-${day}`).format(
      "YYYY-MM-DD"
    );
    setDate(formattedDate);
  };
  return (
    <div className="md:flex md:items-center md:justify-center md:w-full md:h-full">
      <div className="max-w-[600px] mx-auto pt-20 flex flex-col gap-5">
        <h1 className="mb-4 text-xl font-semibold text-center">
          Хичээлийн ирц
        </h1>
        <div className="flex justify-center gap-5">
          <DatePicker
            picker="month"
            format={(date) => dayjs().month(date.month()).format("MMM YYYY")}
            value={month}
            onChange={(dates) => {
              if (dates) {
                setMonth(dates);
                setDaysInMonth(dates.daysInMonth());
              } else {
                const currentMonth = dayjs();
                setMonth(currentMonth);
                setDaysInMonth(currentMonth.daysInMonth());
              }
            }}
          />
          <Select
            placeholder="Хичээл соногох"
            defaultValue={""}
            className="w-40"
          >
            <Select.Option value="">Бүгд</Select.Option>
            <Select.Option value="Buhu">SAT</Select.Option>
            <Select.Option value="Hawt">English</Select.Option>
            <Select.Option value="Irve">Math</Select.Option>
          </Select>
        </div>

        <div className="flex w-full p-3 overflow-x-auto whitespace-nowrap">
          <div className="flex gap-3">
            {daysArray?.map((day, index) => {
              const weekdayName = dayjs(
                `${month.format("YYYY-MM")}-${day}`
              ).format("ddd");
              const isToday = dayjs().date() === day;
              return (
                <div key={`dayInMonth ${index}`}>
                  <Avatar
                    shape="square"
                    size={50}
                    style={{
                      backgroundColor:
                        date ===
                        dayjs(`${month.format("YYYY-MM")}-${day}`).format(
                          "YYYY-MM-DD"
                        )
                          ? "#5c1090"
                          : "#E5E7EB",
                      color:
                        date ===
                        dayjs(`${month.format("YYYY-MM")}-${day}`).format(
                          "YYYY-MM-DD"
                        )
                          ? "white"
                          : "#5c1090",
                    }}
                    className="cursor-pointer"
                    onClick={() => handleDateClick(day)}
                  >
                    <div
                      className={`flex flex-col items-center justify-center  ${
                        isToday ? "text-yellow-600" : ""
                      }`}
                    >
                      <p> {day}</p>
                      <p className="text-[10px]"> {weekdayName}</p>
                    </div>
                  </Avatar>
                </div>
              );
            })}
          </div>
        </div>

        <div className="grid gap-5 pt-10">
          {scheduleAtt.length > 0 ? (
            scheduleAtt?.map((item, i) => {
              const color =
                item.status === "Хоцорсон"
                  ? "yellow"
                  : item.status === "Ирсэн"
                  ? "green"
                  : item.status === "Чөлөөтэй"
                  ? "blue"
                  : item.status === "Тасалсан"
                  ? "red"
                  : "default";
              return (
                <div
                  key={`attendanceInToday ${i}`}
                  className={`pl-3 bg-${color}-500 shadow-xl rounded-l-2xl `}
                >
                  <div className="flex flex-col gap-5 px-10 py-3 bg-white">
                    <div className="flex items-center justify-between">
                      <p>{item.schedule?.course.name}</p>
                      <p className="text-[12px] text-gray-400">
                        <FieldTimeOutlined />
                        <span className="pl-1">
                          {moment(item.schedule?.startTime).format("HH:mm")}-
                          {moment(item.schedule?.endTime).format("HH:mm")}
                        </span>
                      </p>
                    </div>

                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-[12px] text-gray-400">Facility</p>
                        <p>{item.schedule?.facility.name}</p>
                      </div>
                      <div>
                        <p className="text-[12px] text-gray-400">Teacher</p>
                        <p>{item.schedule?.system_user.firstName}</p>
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-[12px] text-gray-400">Time left</p>
                        <p>{moment(item.attendanceTime).format("HH:mm")}</p>
                      </div>
                      <div>
                        <p className="text-[12px] text-gray-400">Status</p>

                        <Tag color={color}>{item.status}</Tag>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="mt-5 text-lg text-center text-gray-500">
              Мэдээлэл олдсонгүй
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleAttendance;
